import React from "react";
import BuyerList from "./BuyerList";

const BuyerListContainer = () => {
  return (
    <div className="container-fluid">
        <BuyerList />
    </div>
  );
};
export default BuyerListContainer;
