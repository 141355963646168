import React from "react";
import { Route } from "react-router-dom";
import AddBilling from "../../Billing/AddBiling/AddBilling";
import BuyerListContainer from "../../Customer/BuyerListContainer";
import CustomerContainer from "../../Customer/CustomerContainer";
import EmployeePermission from "../../Customer/EmployeePermission";
import ApexChartPrac from "../../SMS System/ApexChartPrac";
import { GeoChart } from "../../SMS System/GeoChart";
import HeatMap from "../../SMS System/HeatMap";
import JVMap from "../../SMS System/JVMap";
import MapMain from "../../SMS System/MapMain";
import SmsContainer from "../../SMS System/SmsContainer";
import SMSTemplateEntry from "../../SMS System/SMSTamplateEntry";
import SMSTemplate from "../../SMS System/SMSTemplate";
import LeaveApplicationList from "../components/remoteAttendance/leaveApplication/LeaveApplicationList";
import AttendanceStatusContainer from "../views/remoteAttendance/attendanceStatus/AttendanceStatusContainer";
import AttendanceSummeryContainer from "../views/remoteAttendance/attendanceSummery/AttendanceSummeryContainer";
import MovementsContainer from "../views/remoteAttendance/movements/MovementsContainer";
import RemoteAttendanceContainer from "../views/remoteAttendance/RemoteAttendanceContainer";
import TaDaEntryContainer from "../views/remoteAttendance/tada/TaDaEntryContainer";


const routeItems = [

  {
    path: "/hr/calender",
    name: "zone list",
    component: RemoteAttendanceContainer,
    exact: true,
  },
  {
    path: "/hr/remoteAttendance",
    name: "zone list",
    component: AttendanceSummeryContainer,
    exact: true,
  },
  {
    path: "/hr/attendanceStatus",
    name: "attendance status",
    component: AttendanceStatusContainer,
    exact: true,
  },
  {
    path: "/hr/movement",
    name: "movement attendance",
    component: MovementsContainer,
    exact: true,
  },
  {
    path: "/hr/tadaEntry",
    name: "movement attendance",
    component: TaDaEntryContainer,
    exact: true,
  },
  {
    path: "/hr/leaveapplication",
    name: "Leave Application",
    component: LeaveApplicationList,
    exact: true,
  },
  {
    path: "/billing/addBilling",
    name: "Leave Application",
    component: AddBilling,
    exact: true,
  },
  {
    path: "/SMSsystem/sms",
    name: "SMS",
    component: SmsContainer,
    exact: true,
  },
  {
    path: "/Customer/customerEntry",
    name: "SMS",
    component: CustomerContainer,
    exact: true,
  },
  {
    path: "/Customer/BuyerList",
    name: "SMS",
    component: BuyerListContainer,
    exact: true,
  },
  {
    path: "/SMS/TemplateEntry",
    name: "SMS",
    component: SMSTemplateEntry,
    exact: true,
  },
  {
    path: "/Permission/EmployeePermission",
    name: "EmployeePermission",
    component: EmployeePermission,
    exact: true,
  },
];

function getHrRoutes() {
  {
    return routeItems.map((route, index) => (
      <Route
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default getHrRoutes;
