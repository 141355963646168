import React from 'react';
import TadaEntry from '../../../components/tada/tadaEn/TadaEntry';


const TaDaEntryContainer = () => {

    return (
        <div className="container">
            <div className="">
                <TadaEntry />
            </div>
        </div>
    )

}
export default TaDaEntryContainer;
