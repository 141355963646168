import React, { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { createNewUser, getRoleListData, handleChangeUserAction } from "../SMS System/actions/SmsAction";



const EmployeePermission = ({ handleClose }) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const userInput = useSelector((state) => state.sms.inputData);
  const roleListOption = useSelector((state) => state.sms.roleListOption);
  const isLoading = useSelector((state) => state.sms.isLoading);
  const dispatch = useDispatch();



  const onSubmit = () => {
    dispatch(createNewUser(userInput));
  };

  const handleChange = (name, value, e = null) => {
    dispatch(handleChangeUserAction(name, value, e));
  };

  //   useEffect(() => {
  //     dispatch(getRoleListData());
  //   }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row">

          <div className="col-12">
            <div className="card card-custom gutter-b p-5">
              <div className="row mt-3">
                <div className="col-md-9">
                  <div className="mt-2 p-2">
                    <h3 className="mb-0 pb-0">Employee Permission</h3>
                  </div>
                </div>
              </div>

              <hr></hr>
              <form
                className="form form-label-right voyageEngineerForm"
                method="post"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group row">
                  <div className="col-xl-3 col-lg-3 col-md-6 ">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">
                        Enroll
                      </Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="text"
                        name="first_name"
                        placeholder="Enter Enroll"
                        onChange={(e) =>
                          handleChange("UserID", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">Email</Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="text"
                        name="email"
                        placeholder="Enter Email"
                        autocomplete="false"
                        onChange={(e) => handleChange("UserName", e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">
                        Phone No
                      </Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="text"
                        name="phone_no"
                        placeholder="Enter Phone No"
                        onChange={(e) =>
                          handleChange("contactNo", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <Form.Group>
                      <Form.Label className="formFont pl-1">
                        Password
                      </Form.Label>
                      <Form.Control
                        className="formHeight"
                        type="text"
                        name="password"
                        placeholder="Password"
                        autocomplete="false"
                        onChange={(e) =>
                          handleChange("Password", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6">
                    <Form.Label className="formFont pl-1">
                      Role
                    </Form.Label>
                    <RHFInput
                      as={<Select options={roleListOption} />}
                      rules={{ required: false }}
                      name="role_id"
                      disabled
                      defaultValue={{ value: 2, label: 'User' }}
                      register={register}
                      onChange={(option) => {
                        handleChange('RoleID', option.value);
                      }}
                      setValue={setValue}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-6 mt-6">
                    {isLoading && (
                      <Button
                        className="mr-4 saveButton text-white " disabled={true}>
                        <span>Submitting</span>
                        <span className="ml-3 spinner spinner-white"></span>
                      </Button>
                    )}

                    {!isLoading && (
                      <Button
                        className="mr-4 saveButton text-white " variant="" onClick={() => onSubmit()}>
                        Submit
                      </Button>
                    )}

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeePermission;
