import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearField, distributorSubmit, GetBuyerCategoryType, GetBuyerDetails, GetBuyerUnitType, InputHandle } from "../SMS System/actions/SmsAction";
import BuyerList from "./BuyerList";

const Customer = () => {

    const { register, handleSubmit, errors, setFocus, setValue } = useForm();
    const dispatch = useDispatch();

    const itemDataInput = useSelector((state) => state.sms.itemDataInput);
    console.log('itemDataInput :>> ', itemDataInput);
    const buyerCatType = useSelector((state) => state.sms.buyerCatType);
    const buyerUnitType = useSelector((state) => state.sms.buyerUnitType);
    const [selectCategoryID, setSelectCategoryID] = useState(null);
    const [selectUnitID, setSelectUnitID] = useState(null);

    const handleChange = (name, value) => {
        dispatch(InputHandle(name, value));
    };

    useEffect(() => {
        initialData();
        dispatch(GetBuyerCategoryType());
        dispatch(GetBuyerUnitType());
    }, []);

    const initialData = async () => {

    };

    const sendType = [
        { value: "1", label: "Distributor" },
        { value: "2", label: "So" },
    ];

    const entryValidation = () => {
        if (itemDataInput.buyer_name == 0) {
            alert("Please Type Name");
            return false;
        }
        else if (itemDataInput?.address == 0) {
            alert("Please Type Address");
            return false;
        }
        else if (itemDataInput?.contact_Number == 0) {
            alert("Please Type Phone Number");
            return false;
        }

        else if (itemDataInput?.buyer_Category_id == 0) {
            alert("Please Select Category ID");
            return false;
        }
        else {
            return true;
        }
    }

    const onSubmit = async () => {
        const validation = entryValidation();
        if (validation) {
            dispatch(distributorSubmit(itemDataInput));
            dispatch(GetBuyerDetails());
            dispatch(clearField());
            setValue('buyer_Category_id', '')
        }
    };

    return (
        <div>
            {
                <div className="">
                    <div className="row card text-center topback">
                        <div className="col-lg-12 p-3">
                            <span className="headingText">BUYER INFORMATION ENTRY</span>
                        </div>
                    </div>
                    <form
                        className="form form-label-right  mb-0 "
                        onSubmit={handleSubmit(onSubmit)}
                        method="post"
                        encType="multipart/form-data"
                        autoComplete="off"
                    >
                        <div className="form-group row bg-white">
                            <div className="col-lg-3 mt-3">
                                <Form.Group as={Col} controlId="formGridState">
                                    <label className="form-label">Unit</label>
                                    <RHFInput
                                        className="formSelect pt-0 "
                                        as={<Select options={buyerUnitType} />}
                                        rules={{ required: false }}
                                        name="unitId"
                                        register={register}
                                        placeholder="Select Unit"
                                        value={itemDataInput?.unitId}
                                        onChange={(option) => {
                                            setSelectUnitID(option.value)
                                            handleChange("unitId", option.value);
                                        }}
                                        setValue={setValue}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-3  mt-3">
                                <Form.Group as={Col} controlId="formGridState">
                                    <label className="form-label">Category</label>
                                    <RHFInput
                                        className="formSelect pt-0 "
                                        as={<Select options={buyerCatType} />}
                                        rules={{ required: false }}
                                        name="buyer_Category_id"
                                        register={register}
                                        placeholder="Select Category"
                                        value={itemDataInput?.buyer_Category_id}
                                        onChange={(option) => {
                                            setSelectCategoryID(option.value)
                                            handleChange("buyer_Category_id", option.value);
                                        }}
                                        setValue={setValue}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-3 mt-3">
                                <Form.Group as={Col} controlId="formGridState">
                                    <label className="form-label">Buyer Name</label>
                                    <Form.Control
                                        autocomplete="off"
                                        className="form-control reffcss"
                                        type="text"
                                        placeholder="Type Buyer Name"
                                        value={itemDataInput?.buyer_name}
                                        name="buyer_name"
                                        onChange={(e) => {
                                            handleChange("buyer_name", e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-3 mt-3 ">
                                <Form.Group as={Col} controlId="formGridState">
                                    <label className="form-label">Contact Person</label>
                                    <Form.Control
                                        autocomplete="off"
                                        className="form-control reffcss"
                                        type="text"
                                        placeholder="Type Contact Person"
                                        name="address"
                                        value={itemDataInput?.address}
                                        onChange={(e) => {
                                            handleChange("address", e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-3">
                                <Form.Group as={Col} controlId="formGridState">
                                    <label className="form-label">Phone Number</label>
                                    <Form.Control
                                        autocomplete="off"
                                        className="form-control reffcss"
                                        type="number"
                                        placeholder="Type Phone Number"
                                        name="contact_Number"
                                        value={itemDataInput?.contact_Number}
                                        onChange={(e) => {
                                            handleChange("contact_Number", e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-3">
                                <Form.Group as={Col} controlId="formGridState">
                                    <label className="form-label">Email</label>
                                    <Form.Control
                                        autocomplete="off"
                                        className="form-control reffcss"
                                        type="email"
                                        placeholder="Type Email"
                                        name="email"
                                        value={itemDataInput?.email}
                                        onChange={(e) => {
                                            handleChange("email", e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-3">
                                <Form.Group as={Col} controlId="formGridState">
                                    <label className="form-label">What's App Number</label>
                                    <Form.Control
                                        autocomplete="off"
                                        className="form-control reffcss"
                                        type="number"
                                        placeholder="Type What's App Number"
                                        name="whats_app_number"
                                        value={itemDataInput?.whats_app_number}
                                        onChange={(e) => {
                                            handleChange("whats_app_number", e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </div>


                            <div className="col-lg-3 ml-5 mb-5 mt-4">
                                <button type="submit" className="button-62">
                                    Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            }
            <div className="row mt-2 ">
                <div className="col-lg-12 cuspadremove">
                    <div><BuyerList /></div>
                </div>
            </div>


        </div>
    )
}

export default Customer