/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { getEmployeData } from "../../../../../app/modules/Auth/_redux/authCrud";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  const data = getEmployeData();
  console.log('data :>> ', data);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        {/* <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">HR</span>
            <i className="menu-arrow" />
          </NavLink> */}

        {/* <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/hr/remoteAttendance">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Remote Attendance</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/hr/attendanceStatus">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Attendance Status</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/hr/calender">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Attendance Calender</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/hr/leaveapplication">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Leave Application</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/area/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Leave Approval</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/hr/movement">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Movement</span>
                  <i className="menu-arrow" />
                </NavLink>
              </li>

            </ul>
          </div>
        </li> */}
        <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">SMS</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/SMSsystem/sms">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">SMS System</span>
                  <i className="menu-arrow" />
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/SMS/TemplateEntry">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">SMS Template Entry </span>
                  <i className="menu-arrow" />
                </NavLink>
              </li>

            </ul>
          </div>
        </li>
        {
          data?.role === 1 &&
          <li
            className={`menu-item menu-item-submenu`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">PERMISSION</span>
              <i className="menu-arrow" />
            </NavLink>

            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className={`menu-item menu-item-submenu`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/Permission/EmployeePermission">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Employee Permission</span>
                    <i className="menu-arrow" />
                  </NavLink>

                </li>

              </ul>
            </div>
          </li>
        }

        <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">BUYER</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/Customer/customerEntry">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Buyer Entry</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/Customer/BuyerList">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Buyer List </span>
                  <i className="menu-arrow" />
                </NavLink>
              </li>

            </ul>
          </div>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
