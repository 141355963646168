import React from "react";
import Customer from "./Customer";


const CustomerContainer = () => {
  return (
    <div className="container-fluid">
        <Customer />
    </div>
  );
};
export default CustomerContainer;
