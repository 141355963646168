import React from 'react';
import { useForm } from "react-hook-form";
import { RHFInput } from 'react-hook-form-input';
import Select from 'react-select';
import { Form, Button, Spinner, Col } from "react-bootstrap";

const TadaEntry = () => {
    const { handleSubmit, register, setValue, reset } = useForm();
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
    ];

    return (
        <>
            <div className="card card-custom mb-2 pl-5 pr-5  card-top-border p-3">
                <div className="row">
                    <div className="col-md-9">
                        <div className="p-2">
                            <h3>TA DA ENTRY</h3>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <form>
                    <div className="form-group row">
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Date</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Start Time</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">End Time</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Movement D Hour</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">From Address</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Movement</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">To Address</label>
                                <RHFInput
                                    as={<Select options={options} />}
                                    rules={{ required: true }}
                                    name="reactSelect"
                                    register={register}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Night Stay</label>
                                <RHFInput
                                    as={<Select options={options} />}
                                    rules={{ required: true }}
                                    name="reactSelect"
                                    register={register}
                                    setValue={setValue}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Payment</label>
                                <Form.Control
                                    type="date"
                                    placeholder="Enter Deadweight"
                                    name="fromDate"
                                    className="fromStyle"

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Start Milage</label>
                                <Form.Control
                                    type="date"
                                    placeholder="Enter Deadweight"
                                    name="fromDate"
                                    className="fromStyle"

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Consm Milage</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">End Milage</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Cng Credit1</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Cng Credit1</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Cng Credit2</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Cng Credit2</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Oil Credit Station</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Oil credit</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Personal Milage</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Total Price(P.milage)</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Petrol Qnt</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Petrol Cost</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">OctaneQnt</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Octane Cost</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Cng Qnt</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Cng  Cost</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Lubracant Cost</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Lubracant Cost</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Bus</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Rickshaw</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Taxi cab</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Train</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">AirPlane Cost</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Other Vehicle</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">mnt Cost</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Ferri/Toll cost</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Own D.A</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Driver D.A</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Own  Hotel</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Driver  Hotel</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Supporting</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Courier Cost</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Other Cost</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                        <div className="col-lg-2">
                            <Form.Group>
                                <label className="form-label">Total Cost</label>
                                <Form.Control
                                    autocomplete="off"
                                    className="formHeight"
                                    type="text"
                                    placeholder="Quantity"
                                    name="itemName"
                                    value=""

                                />
                            </Form.Group>
                        </div>
                    </div>
                </form>

            </div>
            <div className="card card-custom  p-5">
                <div className="react-bootstrap-table table-responsive">
                    <table className="table table table-head-custom table-vertical-center">
                        <thead>
                            <th>SL</th>
                            <th>Item ID</th>
                            <th>Item Name</th>
                            <th>Quantity</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                            <tr>
                                <td>1036</td>
                                <td>R658</td>
                                <td>Md. Khaledur Rahman</td>
                                <td>Permanent</td>
                                <td>Accounts And Finance</td>
                                <td> Finance	Assistant Manager</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export default TadaEntry;
