import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { distributorSubmit, InputHandleForSMSTem, templateSubmit } from "../SMS System/actions/SmsAction";
import TemplateList from "./TemplateList";

const SMSTemplateEntry = () => {

    const { register, handleSubmit, errors, setFocus, setValue } = useForm();
    const dispatch = useDispatch();
    const itemDataInputSMSTEM = useSelector((state) => state.sms.itemDataInputSMSTEM);
    console.log('itemDataInputSMSTEM', itemDataInputSMSTEM)

    useEffect(() => {
        initialData();
    }, []);

    const initialData = async () => {
    };

    const onSubmit = async () => {
        dispatch(templateSubmit(itemDataInputSMSTEM));
    };

    const inputChangeHandle = (name, value) => {
        dispatch(InputHandleForSMSTem(name, value));
    };

    return (
        <div>
            {
                <div className="">
                    <div className="row card text-center topback">
                        <div className="col-lg-12 p-3">
                            <span className="headingText">SMS Template Entry</span>
                        </div>
                    </div>
                    <form
                        className="form form-label-right  mb-0 "
                        onSubmit={handleSubmit(onSubmit)}
                        method="post"
                        encType="multipart/form-data"
                        autoComplete="off"
                    >
                        <div className="form-group row bg-white">

                            <div className="col-lg-5 mt-3">
                                <label className="form-label ml-4">Title</label>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Control
                                        as="textarea"
                                        rows="2"
                                        autoComplete="off"
                                        type="textarea"
                                        placeholder="Type Here"
                                        name="template_name"
                                        value={itemDataInputSMSTEM.template_name}
                                        style={{ fontSize: "15px" }}
                                        className="italic-placeholder cuscard bgtypehere"
                                        onChange={(e) => {
                                            inputChangeHandle("template_name", e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-7 mt-3">
                                <label className="form-label ml-4">Details</label>
                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Control
                                        as="textarea"
                                        rows="6"
                                        autoComplete="off"
                                        type="textarea"
                                        placeholder="Type Here"
                                        name="description"
                                        value={itemDataInputSMSTEM.description}
                                        style={{ fontSize: "15px" }}
                                        className="italic-placeholder cuscard bgtypehere"
                                        onChange={(e) => {
                                            inputChangeHandle("description", e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </div>

                            <div className="col-lg-3 ml-5 mb-5 mt-4">
                                <button type="submit" className="button-62">
                                    Add
                                </button>
                            </div>
                            <div className="col-lg-12">
                                <TemplateList />

                            </div>

                        </div>
                    </form>
                </div>
            }

        </div>
    )
}

export default SMSTemplateEntry



