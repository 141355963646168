import React, { useEffect, useState } from "react";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
import { DeleteBuyerList, GetBuyerDetails } from "../SMS System/actions/SmsAction";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

const BuyerList = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetBuyerDetails());
    }, [])

    const buyerData = useSelector((state) => state.sms.buyerData);
    const buyerLoading = useSelector((state) => state.sms.buyerLoading);

    const handleDeleteItem = (item) => {
        console.log('item', item)
        confirmAlert({
            title: 'Are you sure?',
            desc: 'You want to Delete Movement',
            buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    dispatch(DeleteBuyerList(item.buyerId))
                  }
                },
                {
                  label: 'No',
                  onClick: () => alert('Click No')
                }
              ]
        });
    };

    return (
        <>
            <div className="bg-white p-5">

                {!buyerLoading && (
                    <>
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="text-center mb-3 button-00"><span>BUYER LIST</span></div>
                            </div>
                            <div className="col-lg-12">
                                <div className="react-bootstrap-table table-responsive">
                                    <table className="table table-bordered border-bottom table-hover">
                                        <thead className="buyerlist-table">
                                            <th className="text-center">SL</th>
                                            <th>Buyer Name</th>
                                            <th>Contact Person</th>
                                            <th>Email</th>
                                            <th>Contact Number</th>
                                            <th>What's App Number</th>
                                            <th>Unit</th>
                                            <th>Category</th>
                                            <th className="text-center">Action</th>
                                        </thead>
                                        <tbody>
                                            {
                                                buyerData?.length > 0 &&
                                                buyerData?.map((item, index) => (
                                                    <tr className="border-bottom">
                                                        <td className="text-center">{index+++1}</td>
                                                        <td>{item.buyerName}</td>
                                                        <td>{item.address}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.contactNumber}</td>
                                                        <td>{item.whatAppNumber}</td>
                                                        <td>{item.unitName}</td>
                                                        <td>{item.buyerCategoryName}</td>
                                                        <td className="text-center">
                                                        <a
                                                            href
                                                            onClick={() => handleDeleteItem(item)}
                                                            title="Delete"
                                                        >
                                                            <i className="fas fa-trash-alt deleteIcon item-list-icon ml-4"></i>
                                                        </a>
                                                    </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {buyerLoading && (
                    <div className="col-lg-12 mt-5">
                        <LoadingSpinner text="Loading Data..." />
                    </div>
                )}
            </div>
        </>
    );
};

export default BuyerList;
