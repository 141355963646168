import React from "react";
import SMS from "./SMS";


const SmsContainer = () => {
  return (
    <div className="container-fluid">
        <SMS />
    </div>
  );
};
export default SmsContainer;
