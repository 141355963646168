import React from 'react';
import { VectorMap } from 'react-jvectormap';

class JVMap extends React.Component {
  customAreas = [];
  state = {
    values: {
      'US-CO': 10,
      'US-KS': 20,
      'US-MO': 30,
    },
    cuslabel: [
      {
        terriId: 1,
        terriName: 'Hatiya',
        international: 'US-CO',
      },
      {
        terriId: 2,
        terriName: 'Noakhali',
        international: 'US-KS',
      },
      {
        terriId: 3,
        terriName: 'Dhaka',
        international: 'US-MO',
      }
    ],
    isCardOpen: false,
    clickedRegion: null,
  };

  handleRegionClick = (event, code) => {
    const { values, cuslabel } = this.state;
    const value = values[code];
    const label = cuslabel.find((item) => item.international === code)?.terriName;
    const clickedRegion = { code, value, label };
    console.log('clickedRegion:', clickedRegion);
    this.setState({ isCardOpen: true, clickedRegion });
  };

  render() {
    const { values, isCardOpen, clickedRegion, cuslabel } = this.state;

    return (
      <div style={{ width: '100%', height: '100vh' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flex: 1 }}>
            <VectorMap
              map="us_aea"
              backgroundColor="#ffffff"
              containerStyle={{
                width: '100%',
                height: '100%',
              }}
              regionStyle={{
                initial: {
                  fill: '#e0e0e0',
                  'fill-opacity': 1,
                  stroke: 'none',
                  'stroke-width': 0,
                  'stroke-opacity': 1,
                },
                selected: {
                  fill: '#BAE1FF',
                },
              }}
              series={{
                regions: [
                  {
                    values: values,
                    scale: ['#ffffff', '#ff0000'],
                    normalizeFunction: 'polynomial',
                  },
                ],
              }}
              labels={{
                regions: {
                  render: (code) => {
                    const labelObj = cuslabel.find((item) => item.international === code);
                    return labelObj ? labelObj.terriName : '';
                  },
                },
              }}
              onRegionClick={this.handleRegionClick}
              selectedRegions={['US-CO']}
            />
          </div>
          
        </div>

        {isCardOpen && (
          <div
            className="card"
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '20px',
              background: '#DFEDEE',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <h2>{clickedRegion.label}</h2>
            <table>
              <tr>
                <th>Demo</th>
                <th>Demo</th>
                <th>Demo</th>
                <th>Demo</th>
                <th>Demo</th>
                <th>Demo</th>
                <th>Demo</th>
                <th>Demo</th>
                <th>Demo</th>
                <th>Demo</th>
                <th>Demo</th>
                <th>Demo</th>
              </tr>
              <tr>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
              </tr>
              <tr>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
              </tr>
              <tr>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
              </tr>
              <tr>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
                <td>1</td>
              </tr>
            </table>
            <button
              onClick={() => this.setState({ isCardOpen: false })}
              style={{ alignSelf: 'flex-end', marginTop: '10px' }}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default JVMap;
