import React from 'react';
import ReactApexChart from 'react-apexcharts';

class HeatMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },{
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },{
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },{
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },{
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },{
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },{
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },{
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },{
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },{
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 1',
          data: [
            { x: '10:00', y: 15 },
            { x: '10:30', y: 12 },
            { x: '11:00', y: 10 },
            { x: '11:30', y: 8 },
            { x: '12:00', y: 11 },
            { x: '12:30', y: 9 },
            { x: '01:00', y: 7 },
            { x: '01:30', y: 6 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        {
          name: 'Metric 2',
          data: [
            { x: '10:00', y: 9 },
            { x: '10:30', y: 8 },
            { x: '11:00', y: 6 },
            { x: '11:30', y: 4 },
            { x: '12:00', y: 5 },
            { x: '12:30', y: 3 },
            { x: '01:00', y: 2 },
            { x: '01:30', y: 1 }
          ]
        },
        {
          name: 'Metric 3',
          data: [
            { x: '10:00', y: 5 },
            { x: '10:30', y: 3 },
            { x: '11:00', y: 2 },
            { x: '11:30', y: 1 },
            { x: '12:00', y: 3 },
            { x: '12:30', y: 2 },
            { x: '01:00', y: 1 },
            { x: '01:30', y: 0 }
          ]
        },
        
      ],
      options: {
        chart: {
          height: 450,
          type: 'heatmap'
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: 'category',
          categories: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '01:00', '01:30']
        },
        title: {
          text: 'HeatMap Chart (Different color shades for each series)'
        },
        grid: {
          padding: {
            right: 20
          }
        }
      }
    };
  }

  render() {
    return (
      <div id="chart">
        <div className="card">
          <ReactApexChart options={this.state.options} series={this.state.series} type="heatmap" height={850} />
        </div>
      </div>
    );
  }
}

export default HeatMap;
