import React from 'react';
import { VectorMap } from 'react-jvectormap';

class JV2Map extends React.Component {

    customArea = {
        path: 'M100,100 L200,200 L200,100 Z', // Example coordinates for a triangle
        name: 'Custom Area',
        style: {
            fill: '#ff0000', // Fill color of the area
            stroke: '#000000', // Border color of the area
        },
    }
    render() {
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                <VectorMap
                    map="world_mill" // Specify the map to be used
                    backgroundColor="#ffffff" // Background color of the map
                    containerStyle={{
                        width: '100%',
                        height: '100%',
                    }}
                    regionStyle={{
                        initial: {
                            fill: '#e0e0e0', // Default color for regions
                            'fill-opacity': 1,
                            stroke: 'none',
                            'stroke-width': 0,
                            'stroke-opacity': 1,
                        },
                    }}
                    series={{
                        regions: [
                            {
                                values: {
                                    // Specify values for regions if needed
                                    US: '#ff0000',
                                },
                            },
                        ],
                    }}
                    customAreas={[this.customArea]} // Pass the custom area object as an array
                />
            </div>
        );
    }
}

export default JV2Map;
