import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
import { DeleteBuyerList, DeleteTemplateList, GetBuyerDetails, GetTemplateList } from "../SMS System/actions/SmsAction";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

const TemplateList = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetTemplateList());
    }, [])

    const buyerLoading = useSelector((state) => state.sms.buyerLoading);
    const templateData = useSelector((state) => state.sms.templateData);

    const handleDeleteItem = (item) => {
        console.log('delete item', item)
        confirmAlert({
            title: 'Are you sure?',
            desc: 'You want to Delete Movement',
            buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    dispatch(DeleteTemplateList(item.temId))
                  }
                },
                {
                  label: 'No',
                //   onClick: () => alert('Click No')
                }
              ]
        });
    };

    return (
        <>
            <div className="bg-white p-5">

                {!buyerLoading && (
                    <>
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="text-center mb-1 button-00"><span>TEMPLATE LIST</span></div>
                            </div>
                            <div className="col-lg-12">
                                <div className="react-bootstrap-table table-responsive">
                                    <table className="table table-bordered border-bottom">
                                        <thead className="bg-light">
                                            <th className="text-center">SL</th>
                                            <th>TITLE</th>
                                            <th>DETAILS</th>
                                            <th className="text-center">ACTION</th>
                                        </thead>
                                        <tbody>
                                            {
                                                templateData?.length > 0 &&
                                                templateData?.map((item, index) => (
                                                    <tr className="border-bottom">
                                                        <td className="text-center">{index+++1}</td>
                                                        <td>{item.temName}</td>
                                                        <td>{item.temdis}</td>
                                                        <td className="text-center">
                                                        <a
                                                            href
                                                            onClick={() => handleDeleteItem(item)}
                                                            title="Delete"
                                                        >
                                                            <i className="fas fa-trash-alt deleteIcon item-list-icon ml-4"></i>
                                                        </a>
                                                    </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {buyerLoading && (
                    <div className="col-lg-12 mt-5">
                        <LoadingSpinner text="Loading Data..." />
                    </div>
                )}
            </div>
        </>
    );
};

export default TemplateList;
