import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { Form, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { GetBuyerCategoryType,GetBuyerDetailsForSMS,GetBuyerUnitType, GetTemplateList, InputHandleForSMS, sendSms } from "./actions/SmsAction";

const SMS = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const smsStatus = useSelector((state) => state.sms.smsStatus);
  const dataInputSMS = useSelector((state) => state.sms.dataInputSMS);
  const templateData = useSelector((state) => state.sms.templateData);
  console.log('dataInputSMS :>> ', dataInputSMS);
  const buyerDataForSMS = useSelector((state) => state.sms.buyerDataForSMS);
  console.log('buyerDataForSMS :>> ', buyerDataForSMS);
  const buyerSMSLoading = useSelector((state) => state.sms.buyerSMSLoading);
  const buyerCatType = useSelector((state) => state.sms.buyerCatType);
  const buyerUnitType = useSelector((state) => state.sms.buyerUnitType);

  const [selectCategoryID, setSelectCategoryID] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [smsText, setSmsText] = useState("");
  console.log('smsText :>> ', smsText);
  const [selectedTemplateDescription, setSelectedTemplateDescription] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const smsTemplates = [
    { id: 1, title: "Eid-ul-Adha Mubarak", description: "Eid Mubarak! Wishing you a joyous and blessed Eid Ul Adha with lots of laughter, joy, and good health!Eid Mubarak!" },
  
  ];

  useEffect(() => {
    initialData();
    dispatch(GetBuyerCategoryType());
    dispatch(GetBuyerUnitType());
    dispatch(GetTemplateList());
  }, []);

  const initialData = async () => {
    // Initialize data if needed
  };

  const showReport = () => {
    dispatch(GetBuyerDetailsForSMS(dataInputSMS?.Category,dataInputSMS?.unit));
  };
  const handleDelete = () => {
   
  };

  const columns = [
    {
      name: "Buyer Name",
      selector: (row) => row.buyerName,
      sortable: true,
    },
    {
      name: "Contact Person",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Contact Number",
      selector: (row) => row.contactNumber,
      sortable: true,
    },
    {
      name: "Buyer Category Name",
      selector: (row) => row.buyerCategoryName,
      sortable: true,
    },
    {
      name: "WhatsApp Number",
      selector: (row) => row.whatAppNumber,
      sortable: true,
    },
  ];

  const handleChange1 = (state) => {
    setSelectedData(state.selectedRows);
  };

  const smsSend = () => {
    if (smsText === "" && selectedTemplateDescription === "") {
      alert("Please Type Your Message");
    } else {
      const message = smsText || selectedTemplateDescription;
      dispatch(sendSms(selectedData, message));
    }
  };

  const templateColumns = [
    {
      name: "SMS TITLE",
      selector: (row) => row.temName,
      sortable: true,
    },
  ];

  const expandedTemplateComponent = ({ data }) => (
    <div className="expanded-template">
      <p>{data.temdis}</p>
    </div>
  );

  const handleRowExpand = (row) => {
    setSelectedTemplateDescription(row.temdis);
    setSmsText(row.temdis);
  };

  const handleTemplateSelect = (state) => {
    if (state.selectedCount === 0) {
      setSelectedTemplate(null);
      setSmsText("");
    } else {
      const selectedTemplateId = state.selectedRows[0].temId;
      const selectedTemplate = templateData.find(
        (template) => template.temId === selectedTemplateId
      );
      setSelectedTemplate(selectedTemplate);
      setSmsText(selectedTemplate.temdis);
    }
  };

  const handleSmsTextChange = (e) => {
    setSmsText(e.target.value);
  };

  const inputChangeHandle = (name, value) => {
    dispatch(InputHandleForSMS(name, value));
  };

  return (
    <div>
      {!smsStatus && (
        <>
          <div className="card p-3 dashboardCart">
            <span className="button-010 mb-5">SMS SYSTEM</span>
            <div className="form-group row">
              <div className="col-lg-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">Unit</label>
                  <Select
                    className="formSelect pt-0 zindex"
                    options={buyerUnitType}
                    placeholder="Select Unit"
                    onChange={(option) => {
                      setSelectCategoryID(option.value);
                      inputChangeHandle("unit", option.value);
                    }}
                  
                  />
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group as={Col} controlId="formGridState">
                  <label className="form-label">Category</label>
                  <Select
                    className="formSelect pt-0 zindex"
                    options={buyerCatType}
                    placeholder="Select Category"
                    onChange={(option) => {
                      // setSelectCategoryID(option.value);
                      inputChangeHandle("Category", option.value);
                    }}
                  />
                </Form.Group>
              </div>

              <div className="col-md mt-5 mb-5">
                <Button className="button-Go mt-1" size="sm" onClick={showReport}>
                  Show
                </Button>
              </div>

            </div>
            <div className=" paddzero">
              <DataTable
                columns={columns}
                data={buyerDataForSMS}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="450px"
                selectableRowsHighlight
                highlightOnHover
                selectableRows
                onSelectedRowsChange={handleChange1}
                actions
              />
            </div>

            <div className="p-3 sms">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <div className="col-lg-12 padrightremove">
                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Control
                          as="textarea"
                          rows="14"
                          autoComplete="off"
                          type="textarea"
                          placeholder="Type Here"
                          name="remarks"
                          value={smsText}
                          style={{ fontSize: "15px" }}
                          className="italic-placeholder cuscard bgtypehere"
                          onChange={handleSmsTextChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-12 mt-5 mb-5">
                      <button
                        type="button"
                        className="button-62 ml-5"
                        onClick={smsSend}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 padleftremove">
                  <div className="sms-template">
                    <DataTable
                      columns={templateColumns}
                      data={templateData}
                      selectableRows
                      onSelectedRowsChange={handleTemplateSelect}
                      expandableRows
                      expandOnRowClicked
                      onRowExpand={handleRowExpand}
                      expandableRowsComponent={expandedTemplateComponent}
                      customStyles={{
                        rows: {
                          style: {
                            "&:hover": {
                              backgroundColor: "#F0F0F0",
                              cursor: "pointer",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

        </>
      )}

      {smsStatus && (
        <div>
          {/* <span className="spineercolor">{<LoadingSpinner />}</span> */}
        </div>
      )}
    </div>
  );
};

export default SMS;
