import Axios from "axios";
import * as Types from "../../SMS System/types/Types";
import { showToast } from "../../master/utils/ToastHelper";


export const InputHandle = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_VALUE_INPUT, payload: formData });
};

export const distributorSubmit = (itemDataInput) => async (dispatch) => {
  let newList = [];
  let responseList = {
    submitLoading: true,
    data: {},
    status: true,
  };

  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/SMSTextile/TextileDistributorInfomationInsert`;
    console.log('postUrl :>> ', postUrl);

    await Axios.post(postUrl, itemDataInput)
      .then(function (response) {
        console.log('response :>> ', response);
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.DATA_SUBMIT, payload: responseList });
          dispatch(GetBuyerDetails());

        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.DATA_SUBMIT, payload: responseList });
        }
      })
      .catch(function (error) {
        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.DATA_SUBMIT, payload: responseList });
      });
  } catch (error) {
    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.DATA_SUBMIT, payload: responseList });
  }
};
export const templateSubmit = (itemDataInput) => async (dispatch) => {
  let newList = [];
  let responseList = {
    submitLoading: true,
    data: {},
    status: true,
  };

  try {
    let postUrl = `${process.env.REACT_APP_API_PUBLIC_URL}api/SMSTextile/TemplateInsertForSMS`;
    console.log('postUrl :>> ', postUrl);

    await Axios.post(postUrl, itemDataInput)
      .then(function (response) {
        console.log('response :>> ', response);
        if (response.data.code === 200) {
          responseList.submitLoading = false;
          responseList.status = true;
          showToast("success", `${response.data.message}`);
          dispatch({ type: Types.TEMPLATE_SUBMIT, payload: responseList });
          dispatch(GetTemplateList());

        } else {
          responseList.status = false;
          responseList.submitLoading = false;
          showToast("error", response.data.messege);
          dispatch({ type: Types.TEMPLATE_SUBMIT, payload: responseList });
        }
      })
      .catch(function (error) {
        responseList.status = false;
        responseList.submitLoading = false;
        const message =
          "Something went wrong ! Please fill all inputs and try again !";
        showToast("error", error);
        dispatch({ type: Types.TEMPLATE_SUBMIT, payload: responseList });
      });
  } catch (error) {
    responseList.status = false;
    showToast("error", error);
    dispatch({ type: Types.TEMPLATE_SUBMIT, payload: responseList });
  }
};
export const clearField = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_FIELD, payload: data });
};

export const GetBuyerCategoryType = (divisionId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SMSTextile/GetBuyerCategory`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_BUYER_CATEGORY_TYPE, payload: responseList });
};
export const GetBuyerUnitType = (divisionId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SMSTextile/GetBuyerUnit`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_BUYER_UNIT_TYPE, payload: responseList });
};
export const GetBuyerDetails = () => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SMSTextile/GetBuyerList`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.BUYER_DETAILS, payload: responseList });
};
export const InputHandleForSMS = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_VALUE_INPUT_SMS, payload: formData });
};
export const InputHandleForSMSTem = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_VALUE_INPUT_SMS_TEM, payload: formData });
};
export const GetBuyerDetailsForSMS = (catid,unitid) => async (dispatch) => {
  console.log('catid :>> ', catid);
  console.log('unitid :>> ', unitid);

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SMSTextile/GetBuyerListForSms?`;

  if (catid !== null) {
    url += `&buyerTypeId=${catid}`
  }
  if (unitid !== null) {
    url += `&unitId=${unitid}`
  }
  await Axios.get(url).then((res) => {
    console.log('url :>> ', url);
    console.log('res :>> ', res);
    responseList.data = res.data.data;
    responseList.status = false;
    
  });
  dispatch({ type: Types.BUYER_DETAILS_FOR_SMS, payload: responseList });
};

export const sendSms = (selectedData, smsText) => async (dispatch) => {
  console.log('action selectedData', selectedData)
  console.log('action smsText', smsText)
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };

  const newList = [];

  for (let i = 0; i < selectedData.length; i++) {
    let smsData = {
      userId: 0,
      contactNo: "",
      message: ""
    };
    smsData.userId = selectedData[i].buyerId;
    smsData.contactNo = selectedData[i].contactNumber;
    smsData.message = smsText;
    newList.push(smsData);
  }
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SMSTextile/SmsServiceStoreForTextile`;
  console.log('newList', newList)
  console.log('url', url)
  await Axios.post(url, newList).then((res) => {

    console.log('res', res)
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    showToast('success', "Data send Successfully");
  });
  dispatch({ type: Types.SMS_SEND_FROM_IMS, payload: responseList });
};
export const DeleteBuyerList = (buyerId) => (dispatch) => {

  let isLoading = true;
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SMSTextile/TextileSmsDistributorDelete?buyerId=${buyerId}`;
  console.log('url', url);
  Axios.delete(url)
  
    .then((res) => {

      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch(GetBuyerDetails());
      }
    })
}
export const GetTemplateList = () => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SMSTextile/GetTemplateList`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.TEMPLATE_LIST, payload: responseList });
};
export const DeleteTemplateList = (temId) => (dispatch) => {

  let isLoading = true;
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}api/SMSTextile/TemplateListDelete?tempId=${temId}`;
  console.log('url', url);
  Axios.delete(url)
  
    .then((res) => {

      if (res.data.status) {
        const data = res.data;
        showToast('success', data.message);
        dispatch(GetTemplateList());
      }
    })
}

export const createNewUser = (inputData) => (dispatch) => {
  const responseList = {
    isLoading: true,
    rolesList: [],
    rolesListPaginated: null,
  };

try {
    Axios.post(`${process.env.REACT_APP_API_PUBLIC_URL}api/Auth/AddNewUser`, inputData)
    .then((res) => {
        const { data, message } = res.data;
        responseList.rolesList = data.data;
        responseList.message = message;
        responseList.rolesListPaginated = data;
        responseList.isLoading = false;
        showToast('success', res.data.message);
 
    }).catch((err) => {
     
      responseList.isLoading = false;
      responseList.message = "Something went wrong!"
      showToast('error', responseList.message)
    })
} catch (error) {
  
}

};

export const getRoleListData = () => (dispatch) => {
  const responseList = {
    isLoading: true,
    rolesList: [],
    rolesListPaginated: null,
  };
  
  Axios
    .get(`${process.env.REACT_APP_API_PUBLIC_URL}api/Auth/AddNewUser`)
    .then((res) => {
      
      const { data, message } = res.data;
      responseList.rolesList = data.data;
      responseList.message = message;
      responseList.rolesListPaginated = data;
      responseList.isLoading = false;
      dispatch({ type: Types.GET_USER_ROLE_TYPE, payload: responseList });
    });
};

export const handleChangeUserAction = (name, value, e
  ) => (dispatch) => {
  
    let data = {
      name: name,
      value: value,
    };
    dispatch({ type: Types.GET_USER_ROLE_INPUT_DATA, payload: data });
  };
