import React from 'react'
import { getEmployeData } from '../../../app/modules/Auth/_redux/authCrud';

const WelCome = () => {

    const data = getEmployeData();
    return (
        <>
            
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            
                <div class="dashboardcard" >
                <img class="loginpageLogo " src="/media/logos/akij-textile.svg" alt="logo" />
                    <h1 className='bg-warning p-3 mt-2'>"Welcome To Akij Textile SMS System"</h1>
                </div>
            </div>
        </>


    )
}

export default WelCome